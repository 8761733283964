export const mergeTagPlugin = (editor, options) => {
  const type = 'merge-tag'
  const defType = editor.DomComponents.getType('default')

  editor.DomComponents.addType(type, {
    isComponent: (el) => el.tagName === type.toUpperCase(),
    model: {
      defaults: {
        tagName: type,
        draggable: false,
        editable: false,
        droppable: false,
        traits: []
      }
    },
    view: {
      render() {
        // renders the component and we override the style to be easy to understand that is a tag
        defType.view.prototype.render.apply(this, arguments)
        this.el.style =
          'border: 1px dashed lightblue; display: inline-block; padding: 2px 5px'
        this.el.innerHTML = this.model.getAttributes().label
        this.el.setAttribute('contenteditable', false)

        return this
      }
    }
  })
}
