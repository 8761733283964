import React, { useCallback, useEffect, useMemo } from 'react'

import { debounce } from 'lodash'

import { Link } from '@tiptap/extension-link'
import { TextAlign } from '@tiptap/extension-text-align'
import { Underline } from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/react'
import { StarterKit } from '@tiptap/starter-kit'

import Attachments from '../Attachments'
import { Indent } from './extensions/Indent'
import MergeTagNode from './extensions/MergeTag'
import Toolbar from './Toolbar'

import styles from './styles.module.scss'

export const tipTapExtensions = [
  StarterKit,
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: 'https'
  }),
  MergeTagNode,
  Indent,
  Underline,
  TextAlign.configure({
    types: ['heading', 'paragraph']
  })
]

export const PlainTextEditor = ({ emailTemplate, onSave, onChange }) => {
  const onEditorSave = useMemo(() => debounce(onSave, 500), [onSave])

  const editor = useEditor({
    extensions: tipTapExtensions,
    content: emailTemplate.html || ''
  })

  const onUpdate = useCallback(() => {
    onChange()

    onEditorSave({ html: editor.getHTML() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, onChange])

  useEffect(() => {
    editor.on('update', onUpdate)
    return () => {
      editor.off('update', onUpdate)
    }
  }, [editor, onUpdate])

  return (
    <div className={styles.container}>
      <div className={styles.editorContainer}>
        <Toolbar editor={editor} />
        <EditorContent editor={editor} className={styles.editor} />

        <Attachments
          attachments={emailTemplate.attachments || []}
          templateId={emailTemplate.id}
        />
      </div>
    </div>
  )
}
