import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AttachmentIcon,
  BoldIcon,
  BulletListIcon,
  Divider,
  Dropdown,
  FormatIcon,
  IndentIcon,
  ItalicIcon,
  LinkIcon,
  OrderedListIcon,
  OutdentIcon,
  PlainButton,
  PlainIconButton,
  PointerDownIcon,
  RedoIcon,
  UnderlineIcon,
  UndoIcon
} from '@fullfabric/alma-mater'

import { useMergeTags } from 'apps/EmailTemplates/hooks/useMergeTags'
import DropDownMergeTags from '../../DropdownMergeTags'
import { ActiveAlignIcon } from '../ActiveAlignIcon'
import { FileUploadModal } from '../FileUploadModal'
import { LinkModal } from '../LinkModal'

import styles from './styles.module.scss'

export default function Toolbar({ editor, modalTargetContainer }) {
  const { t } = useTranslation()
  const [isFileUploadOpen, setIsFileUploadOpenOpen] = useState(false)
  const [, triggerUpdate] = useState(0)
  const [isLinkOpen, setIsLinkOpen] = useState(false)
  const mergeTags = useMergeTags()

  // We're using this toolbar in grapejs editor, so since an iframe and this toolbar outside of it,
  // we need to listen to the editor events to update the toolbar state
  useEffect(() => {
    if (!editor) return

    const updateToolbar = () => triggerUpdate((prev) => prev + 1)

    editor.on('selectionUpdate', updateToolbar)
    editor.on('transaction', updateToolbar)

    return () => {
      editor.off('selectionUpdate', updateToolbar)
      editor.off('transaction', updateToolbar)
    }
  }, [editor])

  if (!editor) {
    return null
  }

  return (
    <>
      <LinkModal
        editor={editor}
        isOpen={isLinkOpen}
        modalTargetContainer={modalTargetContainer}
        onClose={() => setIsLinkOpen(false)}
      />
      <FileUploadModal
        editor={editor}
        isOpen={isFileUploadOpen}
        modalTargetContainer={modalTargetContainer}
        onClose={() => setIsFileUploadOpenOpen(false)}
      />
      <div className={styles.container}>
        <div className={styles.editorMainActions}>
          <Dropdown disablePortal>
            <Dropdown.Toggle>
              <PlainIconButton>
                <FormatIcon />
              </PlainIconButton>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Menu.Option
                onClick={() => editor.chain().focus().setParagraph().run()}
              >
                {t('Default')}
              </Dropdown.Menu.Option>
              <Dropdown.Menu.Option
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
              >
                {t('Quote')}
              </Dropdown.Menu.Option>
              <Dropdown.Menu.Option
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 1 }).run()
                }
              >
                {t('Heading 1')}
              </Dropdown.Menu.Option>
              <Dropdown.Menu.Option
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 2 }).run()
                }
              >
                {t('Heading 2')}
              </Dropdown.Menu.Option>
              <Dropdown.Menu.Option
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 3 }).run()
                }
              >
                {t('Heading 3')}
              </Dropdown.Menu.Option>
              <Dropdown.Menu.Option
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 4 }).run()
                }
              >
                {t('Heading 4')}
              </Dropdown.Menu.Option>
            </Dropdown.Menu>
          </Dropdown>
          <Divider />
          <PlainIconButton
            active={editor.isActive('bold')}
            onClick={() => editor.chain().focus().toggleBold().run()}
          >
            <BoldIcon />
          </PlainIconButton>
          <PlainIconButton
            active={editor.isActive('italic')}
            size='small'
            onClick={() => editor.chain().focus().toggleItalic().run()}
          >
            <ItalicIcon />
          </PlainIconButton>
          <PlainIconButton
            active={editor.isActive('underline')}
            size='small'
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          >
            <UnderlineIcon />
          </PlainIconButton>

          <PlainIconButton
            active={editor.isActive('link')}
            onClick={() => setIsLinkOpen(true)}
          >
            <LinkIcon />
          </PlainIconButton>

          <PlainIconButton
            active={editor.isActive('bulletList')}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
          >
            <BulletListIcon />
          </PlainIconButton>
          <PlainIconButton
            active={editor.isActive('orderedList')}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
          >
            <OrderedListIcon />
          </PlainIconButton>
          <Divider />
          <PlainIconButton
            onClick={() => editor.chain().focus().outdent().run()}
          >
            <OutdentIcon />
          </PlainIconButton>
          <PlainIconButton
            onClick={() => editor.chain().focus().indent().run()}
          >
            <IndentIcon />
          </PlainIconButton>

          <Dropdown disablePortal>
            <Dropdown.Toggle>
              <PlainIconButton>
                <ActiveAlignIcon editor={editor} />
              </PlainIconButton>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Menu.Option
                onClick={() =>
                  editor.chain().focus().setTextAlign('left').run()
                }
              >
                <AlignLeftIcon />
              </Dropdown.Menu.Option>
              <Dropdown.Menu.Option
                onClick={() =>
                  editor.chain().focus().setTextAlign('right').run()
                }
              >
                <AlignRightIcon />
              </Dropdown.Menu.Option>
              <Dropdown.Menu.Option
                onClick={() =>
                  editor.chain().focus().setTextAlign('center').run()
                }
              >
                <AlignCenterIcon />
              </Dropdown.Menu.Option>
              <Dropdown.Menu.Option
                onClick={() =>
                  editor.chain().focus().setTextAlign('justify').run()
                }
              >
                <AlignJustifyIcon />
              </Dropdown.Menu.Option>
            </Dropdown.Menu>
          </Dropdown>
          <Divider />
          <PlainIconButton onClick={() => setIsFileUploadOpenOpen(true)}>
            <AttachmentIcon />
          </PlainIconButton>
          <Dropdown disablePortal>
            <Dropdown.Toggle>
              <PlainButton className={styles.mergeTagButton}>
                {t('Merge tags')} <PointerDownIcon />
              </PlainButton>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <DropDownMergeTags
                mergeTags={mergeTags}
                onAddTag={(_, data) => {
                  editor.commands.insertContent({
                    type: 'merge-tag',
                    attrs: {
                      value: data.value,
                      label: data.name
                    }
                  })
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={styles.undoRedoActions}>
          <PlainIconButton onClick={() => editor.chain().focus().undo().run()}>
            <UndoIcon />
          </PlainIconButton>
          <PlainIconButton onClick={() => editor.chain().focus().redo().run()}>
            <RedoIcon />
          </PlainIconButton>
        </div>
      </div>
    </>
  )
}
