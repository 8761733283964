.react-app ._topContainer_xtw4n_1 {
  background-color: var(--ff-bg-base-lighter);
  height: 56px;
  padding: var(--space-default);
  display: flex;
  justify-content: space-between;
}

.react-app ._backButton_xtw4n_9 {
  display: flex;
  cursor: pointer;
  gap: var(--space-small);
  align-items: center;
  width: 55px;
}

.react-app ._container_xtw4n_17 {
  height: calc(100vh - 130px);
  overflow: auto;
  z-index: 1;
}