.react-app ._container_1ymy0_1 {
  padding: var(--space-small) var(--space-default);
  width: 100%;
  display: flex;
  border-bottom: var(--border-base-lighter);
  justify-content: space-between;
}

.react-app ._leftActions_1ymy0_9,
.react-app ._devices_1ymy0_10 {
  display: flex;
  gap: var(--space-small);
  align-items: center;
}

.react-app ._undoRedo_1ymy0_16 {
  display: flex;
  gap: var(--space-tiny);
}

.react-app ._device_1ymy0_10 {
  margin: 0;
}