.react-app :root {
  --box-size: 16px;
  --label-line-height: 20px;
}

.react-app ._container_w0pjq_6 {
  font-family: var(--font-family-default);
  margin-bottom: var(--space-small);
  display: flex;
  align-items: flex-start;
}
.react-app ._container_w0pjq_6:last-child {
  margin-bottom: 0;
}
.react-app ._container_w0pjq_6 ._hiddenInput_w0pjq_15 {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  margin: 0;
}
.react-app ._container_w0pjq_6 ._checkable_w0pjq_23 {
  cursor: pointer;
  position: static;
  margin: 0;
  display: inline-block;
  width: 100%;
  text-align: justify;
}
.react-app ._container_w0pjq_6 ._checkable_w0pjq_23._disabled_w0pjq_31 {
  cursor: default;
}

.react-app ._withError_w0pjq_35:last-child {
  margin-bottom: var(--space-tiny);
}

.react-app ._buttonPlaceholder_w0pjq_39 {
  width: var(--box-size);
  height: var(--box-size);
  flex-shrink: 0;
}

.react-app ._labelContainer_w0pjq_45 {
  color: var(--ff-color-text-base-darkest);
  position: relative;
  width: 100%;
  padding-left: var(--space-small);
}

.react-app ._labelRow_w0pjq_52 {
  display: inline-flex;
  flex-wrap: nowrap;
}

.react-app ._checkbox_w0pjq_57 ._labelText_w0pjq_57._disabled_w0pjq_31,
.react-app ._radio_w0pjq_58 ._labelText_w0pjq_57._disabled_w0pjq_31 {
  cursor: default;
  color: var(--ff-color-text-base);
}
.react-app ._checkbox_w0pjq_57 ._labelText_w0pjq_57._readOnly_w0pjq_62,
.react-app ._radio_w0pjq_58 ._labelText_w0pjq_57._readOnly_w0pjq_62 {
  cursor: default;
  color: var(--ff-color-text-base-darkest);
}
.react-app ._checkbox_w0pjq_57 ._labelText_w0pjq_57._readOnly_w0pjq_62:not(._checked_w0pjq_67),
.react-app ._radio_w0pjq_58 ._labelText_w0pjq_57._readOnly_w0pjq_62:not(._checked_w0pjq_67) {
  color: var(--ff-color-text-base-darker);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23:before,
.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23:before {
  content: "";
  position: absolute;
  top: calc(var(--label-line-height) / 2);
  transform: translateY(-50%);
  left: calc(-1 * var(--box-size));
  box-sizing: border-box;
  width: var(--box-size);
  height: var(--box-size);
  box-shadow: var(--shadow-1);
  border: var(--border-base-light);
  background: var(--ff-bg-gradient-lightest);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23:after,
.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23:after {
  opacity: 0;
  display: block;
  position: absolute;
  top: calc(var(--label-line-height) / 2);
  left: calc(-1 * var(--box-size) / 2);
  transform: translateY(-50%) translateX(-50%);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._checked_w0pjq_67:after,
.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23._checked_w0pjq_67:after {
  opacity: 1;
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._checked_w0pjq_67:not(._disabled_w0pjq_31, ._readOnly_w0pjq_62):before, .react-app ._radio_w0pjq_58 ._checkable_w0pjq_23._checked_w0pjq_67:not(._disabled_w0pjq_31, ._readOnly_w0pjq_62):before {
  background: var(--ff-bg-gradient-primary);
  border: var(--border-primary-darker);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._disabled_w0pjq_31,
.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23._disabled_w0pjq_31 {
  cursor: default;
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._disabled_w0pjq_31:before,
.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23._disabled_w0pjq_31:before {
  background: var(--ff-bg-base-light);
  border: var(--border-base-lighter);
  box-shadow: var(--shadow-0);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._readOnly_w0pjq_62:before,
.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23._readOnly_w0pjq_62:before {
  background: var(--ff-bg-base-light);
  border: none;
  box-shadow: none;
}

.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23:before {
  border-radius: var(--border-radius-default);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23:after {
  height: 18px;
  width: auto;
  content: url("data:image/svg+xml;charset=UTF-8, <svg height='12' width='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M8.86607 2.39098L4.75008 6.78147 3.13393 5.05754c-.48872-.52131-1.27867-.52131-1.76739 0-.48872.52131-.48872 1.36394 0 1.88525l2.49984 2.66656c.24374.25999.56372.39065.8837.39065.31998 0 .63996-.13066.88369-.39065l4.99973-5.33311c.4887-.52131.4887-1.36394 0-1.88526-.4888-.52131-1.27871-.52131-1.76743 0z' fill='%23F9F9FA'/></svg>");
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._checked_w0pjq_67._disabled_w0pjq_31:after {
  content: url("data:image/svg+xml;charset=UTF-8, <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M8.87 2.4L4.75 6.77 3.13 5.06a1.2 1.2 0 00-1.76 0 1.4 1.4 0 000 1.88l2.5 2.67c.24.26.56.39.88.39.32 0 .64-.13.88-.4l5-5.32a1.4 1.4 0 000-1.89 1.2 1.2 0 00-1.76 0z' fill='%23B9BFC7'/></svg>");
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._checked_w0pjq_67._readOnly_w0pjq_62:after {
  content: url("data:image/svg+xml;charset=UTF-8, <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M2 6.5L4.5 9L10.5 3' stroke='%230664B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._indeterminate_w0pjq_134:after {
  opacity: 1;
  content: "";
  width: 8px;
  height: 0;
  border-radius: 1px;
  border: solid 1px var(--ff-bg-base-lightest);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._indeterminate_w0pjq_134:not(._disabled_w0pjq_31, ._readOnly_w0pjq_62):before {
  background: var(--ff-bg-gradient-primary);
  border: var(--border-primary-darker);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._indeterminate_w0pjq_134._disabled_w0pjq_31:after {
  content: "";
  border: solid 1px var(--ff-bg-base-darker);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._indeterminate_w0pjq_134._readOnly_w0pjq_62:after {
  content: "";
  border-radius: 0px;
  border: var(--border-primary);
}
.react-app ._checkbox_w0pjq_57 ._checkable_w0pjq_23._readOnly_w0pjq_62:before {
  background: var(--ff-bg-base-light);
  border-radius: 2px;
  border: none;
  box-shadow: none;
}

.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23:before {
  border-radius: 50%;
}
.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23:after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--ff-bg-base-lighter);
  border: none;
}
.react-app ._radio_w0pjq_58 ._checkable_w0pjq_23._readOnly_w0pjq_62:after {
  background-color: var(--ff-color-primary-600);
}

.react-app ._instructions_w0pjq_177 {
  width: 100%;
  display: inline-block;
}