.react-app ._container_4bu01_1 {
  display: flex;
  justify-content: space-between;
}

.react-app ._editorMainActions_4bu01_6,
.react-app ._undoRedoActions_4bu01_7 {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}

.react-app ._mergeTagButton_4bu01_13 {
  color: var(--ff-color-text-base-darkest);
}