.react-app ._container_1shzu_1 {
  display: flex;
  height: 100%;
}

.react-app ._editorWithToolbarContainer_1shzu_6 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}