import React, { useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Field,
  Link,
  Modal,
  ModalTitle,
  Row
} from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

const getSelectedText = (editor) => {
  const { from, to } = editor.view.state.selection
  const linkNode = editor.state.doc.nodeAt(from)

  // Means nothing is selected and we are just clicking on the link
  if (from === to) {
    return linkNode?.textContent
  } else {
    return editor.state.doc.textBetween(
      editor.state.selection.from,
      editor.state.selection.to,
      ' '
    )
  }
}

export const LinkModal = ({
  isOpen,
  onClose,
  editor,
  modalTargetContainer
}) => {
  const { t } = useTranslation()
  const formRef = React.useRef()
  const { control, reset, handleSubmit } = useForm()

  const {
    field: { ref: linkRef, ...linkProps }
  } = useController({ name: 'link', control })
  const {
    field: { ref: textRef, ...textProps }
  } = useController({ name: 'text', control })

  useEffect(() => {
    reset({
      link: editor.getAttributes('link').href || '',
      text: getSelectedText(editor) || ''
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onSubmit = (data) => {
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: data.link })
      .run()

    const currentText = getSelectedText(editor)
    // We only want to insert the text if it's different from the current one
    // because we'll replace everything that is selected even if one is bold
    if (currentText !== data.text) {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .insertContent(data.text)
        .run()
    }

    onClose()
  }

  const onRemoveLink = () => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run()
    onClose()
  }

  return (
    <Modal
      size='medium'
      isOpen={isOpen}
      onClose={onClose}
      container={modalTargetContainer}
      header={<ModalTitle title={t('Add link')} onClose={onClose} />}
      footer={
        <Row
          className={styles.footer}
          align='center'
          justify={editor.getAttributes('link').href ? 'between' : 'end'}
          nogutter
        >
          {editor.getAttributes('link').href && (
            <Link secondary onClick={onRemoveLink}>
              {t('Remove link')}
            </Link>
          )}
          <div>
            <Button onClick={onClose}>{t('Cancel')}</Button>
            <Button primary onClick={() => formRef.current.requestSubmit()}>
              {t('Add link')}
            </Button>
          </div>
        </Row>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Field
          type='text'
          id='text'
          label={t('Text')}
          inputRef={textRef}
          required
          {...textProps}
        />
        <Field
          type='text'
          id='link'
          label={t('Link')}
          inputRef={linkRef}
          required
          {...linkProps}
        />
      </form>
    </Modal>
  )
}
