import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BorderIcon,
  Button,
  PlainIconButton,
  RedoIcon,
  UndoIcon
} from '@fullfabric/alma-mater'
import RadioButtonInput from '@fullfabric/alma-mater/src/components/Input/Radio'

import styles from './styles.module.scss'

export const Toolbar = ({ editor }) => {
  const { t } = useTranslation()
  const [isShowingComponentOutline, setIsShowingComponentOutline] =
    useState(true)
  const [activeDevice, setActiveDevice] = useState('desktop')

  useEffect(() => {
    if (!editor) return
    // Enable component outline by default
    editor.runCommand('core:component-outline')
  }, [editor])

  const handleComponentOutline = () => {
    if (isShowingComponentOutline) {
      editor.stopCommand('core:component-outline')
    } else {
      editor.runCommand('core:component-outline')
    }
    setIsShowingComponentOutline(!isShowingComponentOutline)
  }

  const handleDevice = (device) => {
    setActiveDevice(device)
    editor.setDevice(device)
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftActions}>
        <PlainIconButton
          active={isShowingComponentOutline}
          onClick={handleComponentOutline}
        >
          <BorderIcon />
        </PlainIconButton>

        <div className={styles.undoRedo}>
          <PlainIconButton
            disabled={!editor?.UndoManager.hasUndo()}
            onClick={() => editor.UndoManager.undo()}
          >
            <UndoIcon />
          </PlainIconButton>
          <PlainIconButton
            disabled={!editor?.UndoManager.hasRedo()}
            onClick={() => editor.UndoManager.redo()}
          >
            <RedoIcon />
          </PlainIconButton>
        </div>
      </div>
      <div className={styles.devices}>
        <RadioButtonInput
          id='desktop'
          label='Desktop'
          checked={activeDevice === 'desktop'}
          className={styles.device}
          onChange={() => handleDevice('desktop')}
        />
        <RadioButtonInput
          id='mobile'
          label='Mobile'
          checked={activeDevice === 'Mobile portrait'}
          className={styles.device}
          onChange={() => handleDevice('Mobile portrait')}
        />
      </div>
      <Button size='small' onClick={() => editor.runCommand('preview')}>
        {t('Preview')}
      </Button>
    </div>
  )
}
