.react-app ._container_1x25w_1 {
  width: 320px;
  height: calc(100vh - 74px);
  flex-direction: column;
  padding: var(--space-default);
  display: none;
}

.react-app ._active_1x25w_9 {
  display: flex;
}

.react-app ._noPadding_1x25w_13 {
  padding: 0;
}

.react-app ._expanded_1x25w_17 {
  margin-left: -80px;
  width: 401px;
  background: white;
}