import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowLeftIcon, Text } from '@fullfabric/alma-mater'

import SidebarPanel from '../SidebarPanel'

import styles from './styles.module.scss'

export default function SettingsPanel({ active, editor }) {
  const { t } = useTranslation()
  const [selectedComponent, setSelectedComponent] = useState(null)

  useEffect(() => {
    if (!editor) return

    editor.on('component:selected', setSelectedComponent)

    return () => {
      editor.off('component:selected', setSelectedComponent)
    }
  }, [editor])

  return (
    <SidebarPanel active={active} expanded={!!selectedComponent} noPadding>
      {selectedComponent && (
        <div className={styles.topContainer}>
          <div
            className={styles.backButton}
            onClick={() => setSelectedComponent(null)}
          >
            <ArrowLeftIcon color='text-base-darker' />
            <Text type='f5' fontColor='text-base-darker'>
              {t('Back')}
            </Text>
          </div>
          <Text type='h4' fontColor='text-base-darkest'>
            {selectedComponent.getName()}
          </Text>
          <div className={styles.backButton} />
        </div>
      )}
      <div className={styles.container}>
        <div id='settings-container' className='panel' />
        <div id='styles-container' className='panel' />
      </div>
    </SidebarPanel>
  )
}
