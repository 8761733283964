import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function SidebarPanel({
  active,
  children,
  noPadding,
  expanded
}) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.active]: active,
        [styles.noPadding]: noPadding,
        [styles.expanded]: expanded
      })}
    >
      {children}
    </div>
  )
}
